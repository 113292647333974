/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Şehir merkezinde sur içinde yer alan yapının ilk defa IV-V. yüzyıllarda Aziz Andreas’a izâfe edilen bir manastır kompleksinin içinde yapıldığı kabul edilmektedir. XI-XII. yüzyıllarda üç nefli bir bazilika olarak yenilenmiş, 1214-1235 yılları arasında haç planlı hale getirilmiştir."), "\n", React.createElement(_components.p, null, "Trabzon’un 1461 yılında fethinden sonra Fatih Sultan Mehmet tarafından camiye çevrilmiş ve mimari özelliklerini koruyarak günümüze kadar gelmiştir. Yapının merkezî kubbesi onikigen şeklinde yüksek kasnaklı olup içte pandantiflere oturur. Yan neflerin üzerinde galeriler vardır. Sahınların üstü beşik tonozlarla örtülüdür. Geniş orta nef doğuda içten dairevî, dıştan beşgen bir apsisle son bulmaktadır. Güneydeki protesis ve diokonikon odası kubbemsi tonozla örtülmüştür. XIII. yüzyılda ayrıca batıya Kafkas etkileri ve mahallî etkiler taşıyan bir dış narteks eklenmiştir."), "\n", React.createElement(_components.p, null, "XIII-XIV. yüzyıllarda yapıya kuzeydeki iki sütunlu giriş ve bu kısmın doğusunda iki hücre ilâve edilmiştir. Trabzon Komnenos kralları burada tahta çıkmış, bazıları kilise içerisine ve avlusuna gömülmüştür."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
